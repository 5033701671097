<template>
    <div style="text-align: center; position: absolute; top:0;right: 0; bottom: 0; left: 0;display: table;">
        
            <div style="display:table-cell; vertical-align: middle;">
                <img :src="img"/>
            </div>

    </div>
</template>
<script>
import img from "@/assets/images/default/low_version_cn.png";
export default {
    data() {
        return {
            img
        }
    },
    created() {
        
    },
    methods:{
        gotoHome(){
            
        }
    }
}
</script>
<style scoped>
    .icon{
        font-size: 100px;
    }
    .title{
        font-size: 44px;
        font-weight: bold;
    }
</style>